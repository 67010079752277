import React, { useState } from 'react';

export function useForm(initValues, validateOnChange = false, validate) {
    const [var_values, set_values] = useState(initValues);
    const [var_errors, set_errors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        set_values({
            ...var_values,
            [name]: value,
        });

        if (validateOnChange) {
            validate({ [name]: value });
        }

        set_errors({
            ...var_errors,
            [name]: null,
        });
    };

    const resetForm = () => {
        set_values(initValues);
        set_errors({});
    };

    return {
        var_values,
        set_values,
        var_errors,
        set_errors,
        resetForm,
        handleInputChange,
    };
}

export function Form(props) {
    const { children, ...other } = props;
    return (
        <form autoComplete="off" {...other}>
            {props.children}
        </form>
    );
}
