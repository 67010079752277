import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Chip,
    OutlinedInput,
    Box,
    Checkbox,
    ListItemText,
    ListSubheader,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function MultiSelectGroupBy({
    label,
    var_errors,
    set_errors,
    var_values,
    set_values,
    value,
    name,
    onChange,
    options,
    error = null,
    disabled = false,
    variant = 'outlined',
    ...other
}) {
    function handleClickBilltos(loca) {
        //console.log(loca)
        var temp = [...value];
        //console.log(loca.concat(value).unique())
        for (var i = 0; i < loca.length; i++) {
            //console.log(temp)
            if (temp.indexOf(loca[i]) > -1) {
                temp.splice(temp.indexOf(loca[i]), 1);
            } else {
                temp = [...temp, loca[i]];
            }
        }

        //console.log(temp)
        set_values({
            ...var_values,
            [name]: temp,
        });
        set_errors({
            ...var_errors,
            [name]: null,
        });
    }
    function handleClickLocation(option) {
        var temp = [...value];
        //return;
        var isFound = -1;
        for (var i = 0; i < temp.length; i++) {
            if (temp[i].value === option.value) {
                isFound = i;
            }
        }
        if (isFound !== -1) {
            temp.splice(isFound, 1);
        } else {
            temp = [...temp, option];
        }
        set_values({
            ...var_values,
            [name]: temp,
        });
        set_errors({
            ...var_errors,
            [name]: null,
        });
    }

    return (
        <FormControl
            variant={variant}
            fullWidth
            {...(error && { error: true })}
            {...other}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                limit={1}
                onChange={onChange}
                name={name}
                value={value}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((val) => (
                            <Chip
                                size="small"
                                key={val.value}
                                label={val.label}
                            />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {options &&
                    options.map((main_option, index) => (
                        <>
                            <ListSubheader
                                name={main_option.name}
                                onClick={handleClickBilltos.bind(
                                    this,
                                    main_option.locations
                                )}
                            >
                                {main_option.name}
                            </ListSubheader>
                            {main_option?.locations?.map((option, ind) => (
                                <MenuItem
                                    key={index}
                                    value={option}
                                    onClick={handleClickLocation.bind(
                                        this,
                                        option
                                    )}
                                >
                                    <Checkbox
                                        checked={
                                            value.filter(
                                                (f_val) =>
                                                    f_val.value === option.value
                                            ).length > 0
                                        }
                                    />
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))}
                        </>
                    ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}

export default MultiSelectGroupBy;
