import React from 'react';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup as MuiRadioGroup,
} from '@mui/material';

export default function RadioGroup(props) {
    const { name, label, value, onChange, items, disable, error } = props;

    return (
        <>
            <FormControl error={error}>
                <FormLabel>{label}</FormLabel>
                <MuiRadioGroup
                    row
                    name={name}
                    value={value}
                    onChange={onChange}
                >
                    {items.map((item) => (
                        <FormControlLabel
                            key={item.id}
                            value={item.id}
                            control={<Radio disabled={disable} />}
                            label={item.title}
                        />
                    ))}
                </MuiRadioGroup>
            </FormControl>
        </>
    );
}
